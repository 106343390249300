import axios from 'axios';
import { goTurkiyeClient } from '../client';
import { IUserFavorites, IUserFavoriteReq } from './types';
import { Cookie } from '@/utils/cookie';
import { NodeEnv } from '@wap-client/constants';

export const deleteUserFavorites = (id: string) =>
  goTurkiyeClient.delete(`/userfavorites/${id}`).then((res) => res);

export const postUserFavorites = (params: IUserFavoriteReq) =>
  goTurkiyeClient.post(`/userfavorites`, params).then((res) => res);

export const getUserFavorites = () =>
  goTurkiyeClient.get<IUserFavorites>(`/userfavorites`, []).then((res) => res);

export const getUserCheckInfo = (
  isRedirect: boolean = false,
  errorCallback = () => {},
  successCallback = (authData: any) => {}
) => {
  const redirectUrl =
    process.env.NEXT_PUBLIC_ENV === NodeEnv.Production ? `/` : `/goturkiye`;
  const token = Cookie.get('token');
  if (token) {
    axios
      .get(
        `${process.env.NEXT_PUBLIC_IDENTITY_SERVER_AUTHORITY}/connect/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const resData = res.data as any;
        if (!resData?.name) {
          Cookie.remove('token');
          Cookie.remove('userInfo');
          window.localStorage.clear();
          errorCallback();
          if (isRedirect) window.location.href = redirectUrl;
        }
        if (resData?.name) {
          successCallback(resData);
        }
      })
      .catch((err: any) => console.log(err));
  } else {
    errorCallback();
    if (isRedirect) window.location.href = redirectUrl;
  }
};
