import { Cookie } from '@/utils/cookie';
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

export interface HttpResponse<T = any> {
  data: T;
  status: number;
}

export enum EHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface IParams {
  [key: string]: any;
}

interface HttpOptions {
  baseUrl: string;
}

class HttpService {
  private http: AxiosInstance;

  constructor(options: HttpOptions) {
    this.http = axios.create({
      baseURL: options.baseUrl,
      withCredentials: false,
      headers: this.setupHeaders(),
    });
  }

  // Get authorization token for requests
  private get getAuthorization() {
    const accessToken = Cookie.get('token') || '';
    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  }

  // Initialize service configuration
  public service() {
    this.injectInterceptors();

    return this;
  }

  // Set up request headers
  private setupHeaders(hasAttachment = false): any {
    return hasAttachment
      ? { 'Content-Type': 'multipart/form-data', ...this.getAuthorization }
      : { 'Content-Type': 'application/json', ...this.getAuthorization };
  }

  // Handle HTTP requests
  private async request<T>(
    method: EHttpMethod,
    url: string,
    options: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    this.service();
    try {
      const response: AxiosResponse<T> = await this.http.request<T>({
        method,
        url,
        ...options,
      });
      const responseData = response.data as any;
      return { data: responseData || {}, status: response.status };
    } catch (error: any) {
      return { data: error?.data || {}, status: error?.status || '200' };
    }
  }

  // Perform GET request
  public async get<T>(
    url: string,
    params?: IParams,
    hasAttachment = false,
    options?: any
  ): Promise<HttpResponse<T>> {
    /* boş parametreler urlden kaldırılıyor. */
    const requestData: IParams = {};
    for (const key in params) {
      if (
        params[key] !== null &&
        params[key] !== undefined &&
        params[key] !== '' &&
        params[key] !== 0
      ) {
        requestData[key] = params[key];
      }
    }

    return this.request<T>(EHttpMethod.GET, url, {
      params: requestData,
      headers: { ...this.setupHeaders(hasAttachment), ...options },
    });
  }

  // Perform POST request
  public async post<T, P>(
    url: string,
    payload: P,
    params?: IParams,
    hasAttachment = false
  ): Promise<HttpResponse<T>> {
    return this.request<T>(EHttpMethod.POST, url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Perform UPDATE request
  public async update<T, P>(
    url: string,
    payload: P,
    params?: IParams,
    hasAttachment = false
  ): Promise<HttpResponse<T>> {
    return this.request<T>(EHttpMethod.PUT, url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Perform DELETE request
  public async delete<T>(
    url: string,
    params?: IParams,
    hasAttachment = false
  ): Promise<HttpResponse<T>> {
    return this.request<T>(EHttpMethod.DELETE, url, {
      params,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  public async patch<T, P>(
    url: string,
    payload: P,
    params?: IParams,
    hasAttachment = false
  ): Promise<HttpResponse<T>> {
    return this.request<T>(EHttpMethod.PATCH, url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Inject interceptors for request and response
  private injectInterceptors() {
    // Set up request interceptor
    this.http.interceptors.request.use((request) => {
      // * Perform an action
      // TODO: implement an NProgress
      return request;
    });

    // Set up response interceptor
    this.http.interceptors.response.use(
      (response) => {
        // * Do something

        return response;
      },

      (error) => {
        if (error?.status === 500) {
          return Promise.reject(error);
        } else if (error?.status === 409) {
          return Promise.reject(error);
        }
        if (401 === error.response.status) {
          const originalRequest = error.config;
          const refreshToken = window?.localStorage?.getItem('refreshToken');
          if (refreshToken) {
            return axios
              .post(
                `${process.env.NEXT_PUBLIC_IDENTITY_SERVER_AUTHORITY}/connect/token`,
                {
                  refresh_token: refreshToken,
                  grant_type: 'refresh_token',
                  client_id: process.env.NEXT_PUBLIC_IDENTITY_SERVER_CLIENT_ID,
                },
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                }
              )
              .then(async (res: any) => {
                const resData = res?.data;
                if (resData?.access_token) {
                  const token = resData?.access_token;
                  const refreshToken = resData?.refresh_token;
                  originalRequest.headers.Authorization = 'Bearer ' + token;
                  Cookie.set('token', token);
                  Cookie.set('refreshToken', refreshToken);
                  window.localStorage.setItem('refreshToken', refreshToken);
                  //401 vermeden önceki istediği yeniden atar
                  return this.http
                    .request(originalRequest)
                    .then((response) => {
                      const responseData = response?.data as any;
                      return {
                        data: responseData || {},
                        status: response?.status,
                      };
                    })
                    .catch((error: any) => {
                      return { data: error?.data, status: error?.status };
                    });
                } else {
                  return refreshAuthUserLogout();
                }
              })
              .catch(() => refreshAuthUserLogout());
          } else {
            return refreshAuthUserLogout();
          }
        } else {
          return Promise.reject(error.response);
        }
      }
    );
  }
}

const refreshAuthUserLogout = () => {
  Cookie.remove('token');
  Cookie.remove('userInfo');
  window.localStorage.clear();
  // window.location.reload();
  return Promise.reject('error refreshing token');
};

export default HttpService;
