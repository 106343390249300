import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useScrollToTop = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };

    handleRouteChange();

    return () => {
      window.removeEventListener('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
};

export default useScrollToTop;
