import Cookies, { CookieSetOptions } from 'universal-cookie';

export class Cookie {
  static get<T>(name: string): T {
    const cookies = new Cookies();
    let cookieValue = cookies.get(name);

    return cookieValue || null;
  }

  static set<T>(name: string, value: T, config?: CookieSetOptions) {
    const cookies = new Cookies();
    let val = value as string | object | T;
    let options: CookieSetOptions = {};
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    options.expires = date;
    options.path = '/';

    if (config) {
      options = config;
    }

    cookies.set(name, val as string, options);
  }

  static remove(name: string) {
    const cookies = new Cookies();
    cookies.remove(name, { path: '/' });
  }
}
